.props .prop{
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    width: 90%;
    text-align: center;
    margin: auto;
    height: max-content;
    padding: 50px 0px ;
    border-radius: 20px;
    margin-top: 20px;
    background-image:linear-gradient(rgba(20,20,20,.7), rgba(20,20,20,.7)),url('../../../../assets/italic/colosseum-792202_640.jpg');
    background-size: cover;
}
.two{
    background-image:linear-gradient(rgba(20,20,20,.7), rgba(20,20,20,.7)),url('../../../../assets/italic/florence-5208579_640.jpg')!important;
    background-size: 100%;

}
.three{
    background-image:linear-gradient(rgba(20,20,20,.7), rgba(20,20,20,.7)),url('../../../../assets/italic/canal-6519196_1280.jpg')!important;
    background-size: 100%;

}
     .props{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.prop .icon{
    font-size: 30px;
    color: rgb(249, 252, 255);
}
.prop .text{
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: white;
}
.prop .text p{
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: white!important;
}
.prop .text h1{
    font-size: 35px;
}