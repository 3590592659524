.about{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row-reverse;
    overflow: hidden;
}
.about .about-right{
display: flex;
flex-direction: column;
text-align: center;
 margin-right: 20px;
 gap: 10px;
 width: 50%;
}
.about-right h1{
    line-height: 1.7;
    padding-bottom: 10px!important;
    font-size: 40px;
    font-weight:600;
}
.about .mark{
    width: 50%!important;
    margin: auto;
    height: 5px;
    background-color: #FFB352!important;
}
.about-right p{
    margin-top: 20px;
}
.about-right a{
    background-color: #FFB352;
    width: max-content;
    margin: auto;
    padding:10px;
    font-size: 25px;
    color: aliceblue;
    border-radius: 10px;
    border: 2px solid #FFB352;
}

.about-right a:hover{
    background-color: transparent;
    transition: 0.4s;
    color: black;
}
.about .about-left{
    position: relative;
    /* width: 50%; */
}
.about .banner{
    position: absolute;
    right:20px;
    top:30px;
    width:100px;
}
.about .banner-left{
    position: absolute;
    left:40px;
    top:30px;
    width:100px;
}