/* start taha home */
.taha-home {
  height: 100vh;
  background-image: url("../../../assets/taha-assets/hero-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 10px;
}

.taha-home .banner {
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: space-around;
}

.taha-home .banner .image,
.taha-home .banner .text {
  width: 40%;
}

.taha-home .banner .text,
.taha-home .banner .image {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 1;
}

.taha-home .banner .text .box {
  width: 90%;
  padding: 15px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 24px 0 24px 0;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.taha-home .banner .text .box h1 {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--text-color);
  position: relative;
  transition: all 0.3s ease-out 0s;
  z-index: 1;
  background: linear-gradient(#0278ff, #bd00ff);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.taha-home .banner .text .box h1 img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  animation: bg 3s infinite;
  z-index: -1;
  transform: translateY(50%);
}

.taha-home .banner .text .box p {
  font-size: 2em;
  color: var(--text-color);
}

.taha-home .banner .image .box {
  height: 70%;
  /* background-color: red; */
  position: relative;
  z-index: 1;
}

.taha-home .banner .image .box .back-banner {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 991px) {
  .taha-home .banner {
    flex-direction: column;
    justify-content: space-between;
  }

  .taha-home .banner .image,
  .taha-home .banner .text {
    width: 100%;
  }

  .taha-home .banner .image .box {
    height: 100%;
  }

  .taha-home .banner .text .box h1,
  .taha-home .banner .text .box p {
    font-size: 1.5em;
  }

  .atom-one {
    width: 60px;
    height: 60px;
  }
}
.taha-home + section {
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  margin-bottom: 25px;
}

.taha-home + section .back-circle-one,
.taha-home + section .back-circle-tow {
  position: absolute;
  z-index: -1;
}

.taha-home + section .back-circle-one {
  top: 0;
  right: 0;
}

.taha-home + section .back-circle-tow {
  bottom: 0;
  left: 0;
}

/* start courses section */
.taha-courses {
  padding: 3em 0;
}
.taha-courses .container .course-filter {
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.taha-courses .container .course-filter li {
  padding: 10px;
  border-radius: 24px;
  text-align: center;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.5s;
  margin-left: 15px;
}
@media (max-width: 991px) {
  .taha-courses .container .row {
    justify-content: center;
  }
  .taha-courses .container .course-filter {
    flex-direction: column;
  }
  .taha-courses .container .course-filter li {
    width: 100%;
    margin: 0 0 10px 0;
  }
}
.taha-courses .container .course-filter li.active {
  background-color: #0278ff !important;
  color: #fff;
}
.taha-courses .container .row > div {
  all: unset;
  border-radius: 24px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: block;
}
.taha-courses .container .row > div .image {
  height: 200px;
}
.taha-courses .container .row > div .body {
  padding: 15px 10px 5px 10px;
}
.taha-courses .container .row > div .body .name-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.taha-courses .container .row > div .body .name-price h6 {
  font-weight: bold;
}
.taha-courses .container .row > div .body .name-price p {
  font-size: 15px;
}
.taha-courses .container .row > div .body p {
  font-size: 12px;
}
.taha-courses .container .row > div .content {
  position: relative;
  padding: 15px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.taha-courses .container .row > div .content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 1px;
  background-color: #ecf0f1;
}
.taha-courses .container .row > div .content p {
  margin: 0;
  font-size: 12px;
  color: var(--text-color);
  width: 45%;
}
.taha-courses .container .row > div .content p i {
  margin-left: 10px;
}
.taha-courses .container .row > div .course-footer {
  padding: 10px;
}
.taha-courses .container .row > div .course-footer a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: 24px;
  background-color: #00bd99;
  color: #fff;
  transition: all 0.5s;
  font-weight: bold;
}
.taha-courses .container .row > div .course-footer a > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  color: #00bd99;
  background-color: #fff;
  transition: all 0.5s;
}
.taha-courses .container .row > div .course-footer a > div i {
  color: #00bd99;
}
.taha-courses .container .row > div .course-footer a:hover {
  background-color: #fff;
  color: #00bd99;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
.taha-courses .container .row > div .course-footer a:hover > div {
  background-color: #00bd99;
  color: #fff !important;
}
.taha-courses .container .row > div .course-footer a:hover > div i {
  color: #fff !important;
}

/* start courses */

/* start courses section */
.taha-courses {
  padding: 3em 0;
}
.taha-courses .container .course-filter {
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.taha-courses .container .course-filter li {
  padding: 10px;
  border-radius: 24px;
  text-align: center;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.5s;
  margin-left: 15px;
}
@media (max-width: 991px) {
  .taha-courses .container .row {
    justify-content: center;
  }
  .taha-courses .container .course-filter {
    flex-direction: column;
  }
  .taha-courses .container .course-filter li {
    width: 100%;
    margin: 0 0 10px 0;
  }
}
.taha-courses .container .course-filter li.active {
  background-color: #0278ff !important;
  color: #fff;
}
.taha-courses .container .row > div {
  all: unset;
  border-radius: 24px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: block;
}
.taha-courses .container .row > div .image {
  height: 200px;
}
.taha-courses .container .row > div .body {
  padding: 15px 10px 5px 10px;
}
.taha-courses .container .row > div .body .name-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.taha-courses .container .row > div .body .name-price h6 {
  font-weight: bold;
}
.taha-courses .container .row > div .body .name-price p {
  font-size: 15px;
}
.taha-courses .container .row > div .body p {
  font-size: 12px;
}
.taha-courses .container .row > div .content {
  position: relative;
  padding: 15px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.taha-courses .container .row > div .content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 1px;
  background-color: #ecf0f1;
}
.taha-courses .container .row > div .content p {
  margin: 0;
  font-size: 12px;
  color: var(--text-color);
  width: 45%;
}
.taha-courses .container .row > div .content p i {
  margin-left: 10px;
}
.taha-courses .container .row > div .course-footer {
  padding: 10px;
}
.taha-courses .container .row > div .course-footer a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: 24px;
  background-color: #00bd99;
  color: #fff;
  transition: all 0.5s;
  font-weight: bold;
}
.taha-courses .container .row > div .course-footer a > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  color: #00bd99;
  background-color: #fff;
  transition: all 0.5s;
}
.taha-courses .container .row > div .course-footer a > div i {
  color: #00bd99;
}
.taha-courses .container .row > div .course-footer a:hover {
  background-color: #fff;
  color: #00bd99;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
.taha-courses .container .row > div .course-footer a:hover > div {
  background-color: #00bd99;
  color: #fff !important;
}
.taha-courses .container .row > div .course-footer a:hover > div i {
  color: #fff !important;
}
/* start best plateforms */
.best-plateforms .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.best-plateforms .content > a {
  display: block !important;
  width: calc(90% / 3);
  text-align: center;
  /* padding: 0 !important; */
}
.best-plateforms .content > a .image {
  height: 350px;
}
@media (max-width: 991px) {
  .best-plateforms .content {
    flex-direction: column;
  }
  .best-plateforms .content > a {
    margin-bottom: 15px;
    width: 100%;
  }
}

/* start about section */
.taha-about .image {
  position: relative;
  z-index: 1;
  width: 40%;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 4em;
}

.taha-about .image .circle {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 80%;
}

.taha-about .image .light {
  position: absolute;
  z-index: 2;
  font-size: 5em;
}
.taha-about .about-text {
  width: 100%;
  text-align: start;
}

.taha-about .about-text h5.about-name {
  background: linear-gradient(#0278ff, #bd00ff);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  margin-bottom: 15px;
}

.taha-about .about-boxs {
  padding: 3em 0;
}

.taha-about .about-boxs > div {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  border-radius: 15px;
  background-color: #fff;
  color: var(--text-color);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  font-size: 1.3em;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.5s;
}
.taha-about .about-boxs > div i {
  color: #0278ff;
  margin-left: 10px;
}

@font-face {
  font-family: alibon;
  src: url(../../../assets/alibon-font/AlibonRegular-OVa0O.ttf);
}

svg.svg-animation {
  /* font-family: alibon; */
  width: 100%;
  height: 100%;
}
svg.svg-animation text {
  animation: stroke 7s infinite alternate;
  stroke-width: 2;
  stroke: #365fa0;
  font-size: 5em;
}
@keyframes stroke {
  0% {
    fill: rgba(0, 0, 0, 1);
    stroke: rgba(0, 0, 0, 1);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }
  70% {
    fill: rgba(0, 0, 0, 0);
    stroke: rgba(0, 0, 0, 1);
  }
  80% {
    fill: rgba(0, 0, 0, 0);
    stroke: rgba(0, 0, 0, 1);
    stroke-width: 3;
  }
  100% {
    fill: rgba(0, 0, 0, 1);
    stroke: rgba(0, 0, 0, 1);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}
/* responsive of mo salah */
@media screen and (max-width: 480px) {
  .home .home-up {
    display: none;
    justify-content: center;
  }
  .home {
    height: 100vh !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .home .titles {
    position: relative !important;
    width: 90%;
    margin: auto;
    height: max-content !important;
  }
  .home .titles .title {
    width: 90% !important;
    font-size: 40px;
    margin-bottom: 20px !important;
    margin: auto;
    margin-top: 150px !important;
  }
  .home .titles p {
    font-size: 30px;
    font-weight: 800;
  }
  .home .back {
    display: none;
  }
  .home-down {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
  }
  .home-down img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
  .about {
    height: max-content !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    gap: 10px !important;
  }
  .about-left {
    width: 100% !important;
    margin: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about-left .inas {
    width: 95% !important;
  }
  .about-right {
    width: 90% !important;
    margin: auto !important;
    margin-top: 20px !important;
  }
  .prop {
    display: flex;
    flex-direction: column;
    padding: 10px 0px !important;
  }

  .prop .text h1 {
    font-size: 25px !important;
    line-height: 1.8;
  }
  .prop .text p {
    font-size: 18px;
    width: 95%;
    margin: auto;
    margin-bottom: 10px !important;
  }
  .counter .ico {
    color: rgb(122, 90, 249) !important;
    font-size: 80px !important;
  }
  .counter {
    flex-direction: column;
    gap: 13px;
  }
  .advert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 90% !important;
    margin: auto;
  }
  .advert-left {
    width: 90% !important;
    margin: auto !important;
  }
  .advert-right {
    width: 90% !important;
    margin: auto !important;
    margin-top: 30px !important;
  }
  .advert-right .adv {
    width: 100% !important;
  }
  .mobile-menu-links {
    padding-top: 30px !important;
    background-color: white !important;
    z-index: 30;
  }
  .left-image img {
    width: 100% !important;
  }
  .login-section {
    width: 80% !important;
  }
  .advert .text h3 {
    line-height: 1.8;
    font-weight: 700;
    font-size: 30px !important;
  }
  .adv img {
    width: 45% !important;
  }
  .auth-layout .left-image {
    display: none !important;
  }
}
@media screen and (min-width: 481px) and (max-width: 869px) {
  .home .home-up {
    display: none;
    justify-content: center;
  }
  .home {
    height: 100vh !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .home .titles {
    position: relative !important;
    width: 90%;
    margin: auto;
    height: max-content !important;
  }
  .home .titles .title {
    width: 90% !important;
    font-size: 46px;
    margin-bottom: 20px !important;
    margin: auto;
    margin-top: 150px !important;
  }
  .home .titles p {
    font-size: 36px;
    font-weight: 800;
  }
  .home .back {
    display: none;
  }
  .home-down {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
  }
  .home-down img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
  .about {
    height: max-content !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    gap: 10px !important;
  }
  .about-left {
    width: 100% !important;
    margin: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about-left .inas {
    width: 90% !important;
  }
  .about-right {
    width: 90% !important;
    margin: auto !important;
    margin-top: 20px !important;
  }
  .about-right h4 {
    font-size: 30px !important;
  }
  .prop {
    display: flex;
    flex-direction: column;
    padding: 10px 0px !important;
  }
  .about-right p {
    font-size: 24px !important;
  }
  .prop .text h1 {
    font-size: 25px !important;
    line-height: 1.8;
  }
  .prop .text p {
    font-size: 18px;
    width: 95%;
    margin: auto;
    margin-bottom: 17px !important;
  }

  .left-image img {
    width: 100% !important;
    object-fit: cover;
  }
  .login-section {
    width: 70% !important;
  }
}
/* some edition of abdelmagied */
.course:hover {
  background-color: rgb(122, 90, 249) !important;
  transition: 0.5s !important;
  color: white !important;
}
.image {
  border-color: red !important ;
  background-color: #00bd99 !important;
}
