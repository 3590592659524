.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-image: url("../../../../assets/italic/back.png");
  background-size: cover;
  height: 110vh;
  position: relative;
  overflow: hidden;
}
.home-up {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.home-up .home-up-right {
  width: 40%;
}
.home-up .home-up-right img {
  width: 100%;
  height: 100%;
}
.home-up-left {
  width: 50%;
  margin-top: 60px;
}
.home-up .home-up-left img {
  width: 100%;
  height: 100%;
}
.home .home-down {
  width: 100%;
  position: absolute;
  bottom: 0px;
  /* height: 50%!important; */
}
.home-down img {
  width: 100% !important;
  height: 560px;
}
.home .back {
  position: absolute;
  width: 70%;
  right: -250px;
}
.home .title {
  font-weight: 900;
  line-height: 1.4;
  text-align: center;
  background-image: url("../../../../assets/italic/yellow-bg.png");
  background-size: cover;
  font-family: "Pacifico", cursive;
  font-size: 45px;

}
.home .titles {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content:flex-start;
  align-items:center;
  position: absolute;
  flex-direction: column;
}
.home .titles p{
    font-size: 25px;
    font-weight: 800;
}
